import moment from 'moment-timezone';
import localPrefixes from '../../scripts/envSwitcher/localPrefixes.json';

const { prefix } = localPrefixes;

const {
  AuthServiceClient,
} = require('../api/dashboard/v2/auth_service_grpc_web_pb');
const {
  LoginRequest,
  AuthMeRequest,
  ResetPasswordRequest,
  ResetPasswordByTokenRequest,
  UserResourceRequest,
} = require('../api/dashboard/v2/auth_service_pb');

const {
  ProductTrialServiceClient,
} = require('../api/dashboard/v2/product_trial_grpc_web_pb');
const { ProductTrialRequest } = require('../api/dashboard/v2/product_trial_pb');
const {
  ProductType,
} = require('../api/dashboard/v2/company_product_message_pb');

const {
  TaskDashboardAuthServiceClient,
} = require('../api/soter-task/dashboard/v1/auth_service_grpc_web_pb');
const {
  CreateTaskDashboardUserRequest,
  CheckEmailDashboardUserRequest,
  TaskCheckEmailStatus,
  ConfirmEmailRequest,
  ResendConfirmationRequest,
} = require('../api/soter-task/common/v1/auth_message_pb');

const {
  UsersClient,
} = require('../api/dashboard/v2/users_service_grpc_web_pb');
const {
  UsersRequest,
  UserIds,
  UsersDepartmentRequest,
  UsersSiteRequest,
  UsersJobTitleRequest,
  UsersJobRoleRequest,
  UsersRequestForOverviewReport,
  ActivityPageRequest,
  CreateUsersRequest,
  DeleteUsersRequest,
  EditUsersInfoRequest,
} = require('../api/dashboard/v2/users_service_pb');
const users = require('../api/dashboard/v2/users_service_pb');

const {
  DevicesClient,
} = require('../api/dashboard/v2/devices_service_grpc_web_pb');
const {
  DevicesRequest,
  DevicesSetDepartmentRequest,
  DevicesSetSiteRequest,
  DeviceSetStatusRequest,
  DeviceSetCoachingProgramRequest,
  DevicesReportRequest,
} = require('../api/dashboard/v2/devices_service_pb');

const { UserClient } = require('../api/dashboard/v2/user_service_grpc_web_pb');
const {
  UserRequest,
  UserJobTitleRequest,
  UserDepartmentRequest,
  UserSetProgramRequest,
  UserActivationRequest,
  UserUpdateUserPrivateDataRequest,
  UserServiceCreateUserRequest,
  DeviceLinkHistoryRequest,
  UserSiteRequest,
  CreateDashboardFeedbackRequest,
} = require('../api/dashboard/v2/user_service_pb');

const { SetNoteRequest } = require('../api/dashboard/v2/user_note_message_pb');

const { SsoServiceClient } = require('../api/dashboard/v2/sso_service_grpc_web_pb');
const { SSOUrlRequest } = require('../api/dashboard/v2/sso_service_pb');

const {
  UserTaskClient,
  GetUserTasksJobTitleRequest,
} = require('../api/dashboard/v2/user_task_service_grpc_web_pb');
const {
  RemoveUserTaskRequest,
  GetUserTasksByWorkSessionIdRequest,
  UpdateUserTaskRequest,
  CreateUserTaskJobTitleRequest,
  CreateUserTaskRequest,
} = require('../api/dashboard/v2/user_task_service_pb');

const {
  CompaniesClient,
} = require('../api/dashboard/v2/companies_service_grpc_web_pb');
const {
  CompaniesRequest,
  CompanyRequest,
  CreateDepartmentRequest,
  RemoveDepartmentRequest,
  EditDepartmentRequest,
  CreateSiteRequest,
  RemoveSiteRequest,
  EditSiteRequest,
} = require('../api/dashboard/v2/companies_service_pb');

const {
  ProductStatus,
} = require('../api/dashboard/v2/company_product_message_pb');

const { AdminClient } = require('../api/dashboard/v2/admin_grpc_web_pb');
const { AdminCompanyClient } = require('../api/dashboard-admin/v1/company_grpc_web_pb');
const { AdminDevicesClient } = require('../api/dashboard-admin/v1/device_grpc_web_pb');
const { SegmentsClient } = require('../api/dashboard/v2/segments_grpc_web_pb');

const {
  CreateSegmentRequest,
  RemoveSegmentRequest,
  GetSegmentsRequest,
  CreateTagRequest,
  RemoveTagRequest,
  AddTagToUsersRequest,
  RemoveTagToUserRequest,
  UpdateSegmentRequest,
  UpdateTagRequest,
} = require('../api/dashboard/v2/segments_grpc_web_pb');

const {
  CompaniesAdminRequest,
} = require('../api/dashboard/v2/admin_companies_message_pb');

const {
  GetCompaniesRequest,
  CreateCompanyRequest,
  EditCompanyRequest,
  DeleteCompanyRequest,
  GetAvailableFirmwaresRequest,
  GetAvailableTiersRequest,
  GetAvailableCoachingProgramsRequest,
  GetAvailableLanguagesRequest,
  GetCompanyInfoRequest,
  GetDashboardFeedbackRequest,
} = require('../api/dashboard-admin/v1/company_message_pb');

const {
  AvailableProducts,
  ProductInfo,
} = require('../api/dashboard-admin/v1/product_message_pb');

const {
  WearableSettings,
  TypesWearingDevice,
} = require('../api/dashboard-admin/v1/wearable_settings_message_pb');

const {
  TaskSettings,
} = require('../api/dashboard-admin/v1/task_settings_message_pb');

const {
  ClipgoHubSettings,
  GetHubsRequest,
  UpdateHubRequest,
  CreateHubRequest,
  HubRequest,
} = require('../api/dashboard/v2/admin_hub_message_pb');
const {
  HubServiceClient,
} = require('../api/dashboard/v2/admin_hub_service_grpc_web_pb');
const {
  OverviewClient,
} = require('../api/dashboard/v2/overview_service_grpc_web_pb');
const {
  NewOverviewRequest,
  Period,
  NewData,
} = require('../api/dashboard/v2/overview_service_pb');

const {
  HelpServiceClient,
} = require('../api/dashboard/v2/help_request_service_grpc_web_pb');
const {
  HelpRequest,
  ChangeStatusRequest,
} = require('../api/dashboard/v2/help_request_service_pb');
const {
  DashboardUsersRequest,
  CreateDashboardUserRequest,
  UpdateDashboardUserRequest,
  DashboardUserRequest,
} = require('../api/dashboard/v2/admin_dashboard_users_message_pb');

const { AdminDevicesRequest } = require('../api/dashboard-admin/v1/device_message_pb');

const {
  MyCompanyClient,
} = require('../api/dashboard/v2/my_company_service_grpc_web_pb');
const {
  MyCompanyRequest,
} = require('../api/dashboard/v2/my_company_service_pb');

const {
  TaskDashboardVideoServiceClient,
  // GetRebaData,
} = require('../api/soter-task/dashboard/v1/video_service_grpc_web_pb');

const {
  TaskVideoListRequest,
  CreateTaskVideoRequest,
  UpdateTaskVideoRequest,
  VideoUploadChunkRequest,
  EventsForVideoRequest,
  EventsForRebaWeightRequest,
  TaskVideoRequest,
  TaskVideoComparisonListRequest,
  CreateTaskVideoComparisonRequest,
  UpdateTaskVideoComparisonRequest,
  DeleteTaskVideoComparisonRequest,
  CreateTaskVideosRebaWeightsRequest,
  GetTaskVideoRebaWeightsRequest,
  DeleteTaskVideoRebaWeightsRequest,
} = require('../api/soter-task/common/v1/video_message_pb');

const {
  TaskAssessmentServiceClient,
} = require('../api/soter-task/dashboard/v1/assessmet_service_grpc_web_pb');
const {
  TaskOverviewServiceClient,
} = require('../api/soter-task/dashboard/v1/overview_service_grpc_web_pb');
const {
  SoterTaskOverviewRequest,
} = require('../api/soter-task/common/v1/overview_message_pb');
const {
  CreateAssessmentRequest,
  UpdateAssessmentRequest,
  DeleteAssessmentRequest,
  GetAssessmentsForVideoRequest,
  GetAssessmentListRequest,
} = require('../api/soter-task/common/v1/assessment_message_pb');
const {
  ContactSalesClient,
} = require('../api/dashboard/v2/contact_sales_service_grpc_web_pb');
const {
  ContactSalesRequest,
  ProductType: ContactSalesProductType,
} = require('../api/dashboard/v2/contact_sales_message_pb');
const {
  CreateDashboardUserChatProfileRequest,
  GetDashboardUserChatProfileRequest,
  UpdateDashboardUserChatProfileRequest,
  DashboardUserChatProfile,
  GetPreSignedUrlDashboardUserChatProfileRequest,
  SetDashboardUserChatProfileFileRequest,
  DeleteDashboardUserChatProfileFileRequest,
  GetDashboardUserSignatureRequest,
  GetDashboardUserSignatureResponse,
  GetDashboardUserLatestChatIdRequest,
} = require('../api/dashboard/v2/dashboard_user_chat_profile_service_pb');
const { DashboardUserChatProfileServiceClient } = require('../api/dashboard/v2/dashboard_user_chat_profile_service_grpc_web_pb');

export const { FilterStatus } = require('../api/dashboard/v2/users_message_pb');
export const {
  TaskVideoStatus,
} = require('../api/soter-task/common/v1/video_status_enum_pb');
export const {
  TaskVideoPlatform,
} = require('../api/soter-task/common/v1/video_platform_enum_pb');
export const {
  Risks,
} = require('../api/soter-task/common/v1/video_risks_enum_pb');
export const {
  TaskVideoType,
} = require('../api/soter-task/common/v1/video_type_enum_pb');
export const {
  TaskVideoCategory,
} = require('../api/soter-task/common/v1/video_category_enum_pb');
export const {
  CompanyTagType,
} = require('../api/soter-task/common/v1/company_tag_message_pb');
export const {
  ClipgoInputLoginMethod,
  ClipgoConfigureDeviceMethod,
} = require('../api/dashboard/v2/admin_companies_message_pb');
export const { HubType } = require('../api/dashboard/v2/admin_hub_message_pb');
export const { DashboardUserChatProfileFileType } = require('../api/dashboard/v2/dashboard_user_chat_profile_service_pb');
export const { ErrorCodes } = require('../api/errors/errors_pb');
const {
  ResellersClient,
} = require('../api/dashboard/v2/resellers_service_grpc_web_pb');
const {
  ResellersRequest,
} = require('../api/dashboard/v2/resellers_service_pb');

const {
  JobDictionaryRequest,
  JobDictionaryProfileRequest,
  CreateJobDictionaryProfileRequest,
  CreateJobDictionaryTagRequest,
  CreateJobDictionaryTaskRequest,
  CreateJobDictionaryYoutubeVideoRequest,
  EditJobDictionaryTaskRequest,
  EditJobDictionaryProfileRequest,
  EditLimitedJobDictionaryTaskRequest,
  JobDictionaryTaskRequest,
  RemoveJobDictionaryVideoRequest,
  RemoveJobDictionaryTaskRequest,
  SetTaskVideoRequest,
  DetachJobDictionaryTaskVideoRequest,
  JobDictionaryClient,
} = require('../api/job_dictionary/v1/job_dictionary_service_grpc_web_pb');

const {
  CreateJobDictionaryProfile,
  JobDictionaryBodyProfile,
  JobDictionaryProfile,
  CardsViewConfig,
  ActivityDemandBreakdown,
  CriticalPhysicalDemands,
} = require('../api/job_dictionary/v1/job_profile_message_pb');

const {
  CreateJobDictionaryTask,
  JobDictionaryTask,
  EditLimitedJobDictionaryTask,
} = require('../api/job_dictionary/v1/job_task_profile_message_pb');

const HOST = `https://grpc.${
  window.location.host === 'localhost:3000'
  || window.location.host === '10.0.2.2:3000'
    ? `dashboard.soteranalytics.com:443`
    : `${window.location.host}:${443}`
}`;

// eslint-disable-next-line no-underscore-dangle
const enableDevTools
  = window.location.host !== 'new.dashboard.soteranalytics.com'
    ? window.__GRPCWEB_DEVTOOLS__ || (() => {})
    : () => {};

export const getMetaData = () => ({
  Authorization: localStorage.getItem('token'),
  Language: localStorage.getItem('language'),
  timezone: moment.tz.guess(),
});

export const dashboardUserChatProfileService = new DashboardUserChatProfileServiceClient(HOST);
export const createDashboardUserChatProfileRequest = new CreateDashboardUserChatProfileRequest();
export const getDashboardUserChatProfileRequest = new GetDashboardUserChatProfileRequest();
export const updateDashboardUserChatProfileRequest = new UpdateDashboardUserChatProfileRequest();
export const dashboardUserChatProfile = new DashboardUserChatProfile();
export const getPreSignedUrlRequest = new GetPreSignedUrlDashboardUserChatProfileRequest();
export const setUserProfileFileRequest = new SetDashboardUserChatProfileFileRequest();
export const deleteUserProfileFileRequest = new DeleteDashboardUserChatProfileFileRequest();
export const getChatSignatureRequest = new GetDashboardUserSignatureRequest();
export const getChatSignatureResponse = new GetDashboardUserSignatureResponse();
export const getLatestChatIdRequest = new GetDashboardUserLatestChatIdRequest();

export const authService = new AuthServiceClient(HOST);
export const loginRequest = new LoginRequest();
export const authMeRequest = new AuthMeRequest();
export const resetPasswordRequest = new ResetPasswordRequest();
export const resetPasswordByTokenRequest = new ResetPasswordByTokenRequest();
export const userResourceRequest = new UserResourceRequest();

export const productTrialService = new ProductTrialServiceClient(HOST);
export const productTrialRequest = new ProductTrialRequest();
export const productTypes = ProductType;

export const taskAuthService = new TaskDashboardAuthServiceClient(HOST);
export const checkEmailRequest = new CheckEmailDashboardUserRequest();
export const confirmEmailRequest = new ConfirmEmailRequest();
export const signUpRequest = new CreateTaskDashboardUserRequest();
export const emailStatus = TaskCheckEmailStatus;
export const resendConfirmationRequest = new ResendConfirmationRequest();

export const companiesService = new CompaniesClient(HOST);
export const companiesRequest = new CompaniesRequest();
export const companyRequest = new CompanyRequest();
export const createDepartmentRequest = new CreateDepartmentRequest();
export const removeDepartmentRequest = new RemoveDepartmentRequest();
export const editDepartmentRequest = new EditDepartmentRequest();
export const createSiteRequest = new CreateSiteRequest();
export const removeSiteRequest = new RemoveSiteRequest();
export const editSiteRequest = new EditSiteRequest();
export const productStatus = ProductStatus;

export const adminService = new AdminClient(HOST);
export const adminServiceV2 = new AdminCompanyClient(HOST);
export const adminServiceDevices = new AdminDevicesClient(HOST);
export const adminCompaniesRequest = new CompaniesAdminRequest();
// export const updateAdminCompanyRequest = new UpdateCompanyRequest();
export const deleteAdminCompanyRequest = new DeleteCompanyRequest();

export const adminDevicesRequest = new AdminDevicesRequest();

export const clipgoHubSettings = new ClipgoHubSettings();

export const getCompaniesRequest = new GetCompaniesRequest();
export const createCompanyRequest = new CreateCompanyRequest();
export const editCompanyRequest = new EditCompanyRequest();
export const getAvailableFirmwaresRequest = new GetAvailableFirmwaresRequest();
export const getAvailableTiersRequest = new GetAvailableTiersRequest();
export const getAvailableCoachingProgramsRequest
  = new GetAvailableCoachingProgramsRequest();
export const getAvailableLanguagesRequest = new GetAvailableLanguagesRequest();
export const getCompanyInfoRequest = new GetCompanyInfoRequest();
export const getDashboardFeedbackRequest = new GetDashboardFeedbackRequest();
export const availableProducts = new AvailableProducts();
export const wearableSettings = new WearableSettings();
export const taskSettings = new TaskSettings();
export const productInfoWearablesSettings = new ProductInfo();
export const productInfoJobDictionarySettings = new ProductInfo();
export const productInfoGeniusSettings = new ProductInfo();
export const typesWearingDevice = new TypesWearingDevice();

export const categoriesService = new SegmentsClient(HOST);
export const createCategoryRequest = new CreateSegmentRequest();
export const removeCategoryRequest = new RemoveSegmentRequest();
export const getCategoriesRequest = new GetSegmentsRequest();
export const createEntryRequest = new CreateTagRequest();
export const removeEntryRequest = new RemoveTagRequest();
// export const addEntryToEventRequest = new AddTagToEventRequest();
// export const removeEntryToEventRequest = new RemoveTagToEventRequest();
// export const addEntryToWorkSessionRequest = new AddTagToWorkSessionRequest();
// export const removeEntryToWorkSessionRequest =
//   new RemoveTagToWorkSessionRequest();
export const addEntryToUsersRequest = new AddTagToUsersRequest();
export const removeEntryToUserRequest = new RemoveTagToUserRequest();
// export const addEntryToDashboardUserRequest =
//   new AddTagToDashboardUserRequest();
// export const removeEntryToDashboardUserRequest =
//   new RemoveTagToDashboardUserRequest();
// export const addEntryToDeviceRequest = new AddTagToDeviceRequest();
// export const removeEntryToDeviceRequest = new RemoveTagToDeviceRequest();
export const updateCategoryRequest = new UpdateSegmentRequest();
export const updateEntryRequest = new UpdateTagRequest();

export const hubService = new HubServiceClient(HOST);
export const getHubsRequest = new GetHubsRequest();
export const hubRequest = new HubRequest();
export const createHubRequest = new CreateHubRequest();
export const updateHubRequest = new UpdateHubRequest();

export const myCompanyService = new MyCompanyClient(HOST);
export const myCompanyRequest = new MyCompanyRequest();

export const usersService = new UsersClient(HOST);
export const usersRequest = new UsersRequest();
export const usersIds = new UserIds();
export const usersDepartmentsRequest = new UsersDepartmentRequest();
export const usersJobRoleRequest = new UsersJobRoleRequest();
export const usersJobTitleRequest = new UsersJobTitleRequest();
export const editUsersInfoRequest = new EditUsersInfoRequest();
export const usersSiteRequest = new UsersSiteRequest();
export const usersRequestForOverviewReport
  = new UsersRequestForOverviewReport();
export const activityPageRequest = new ActivityPageRequest();
export const createUsersRequest = new CreateUsersRequest();

const { EditUsers } = EditUsersInfoRequest;
export const EditUserConstructor = EditUsers;
const { CreateUser } = CreateUsersRequest;
export const CreateUserMessageConstructor = CreateUser;
export const deleteUsersRequest = new DeleteUsersRequest();

export const userNoteRequest = new SetNoteRequest();

export const devicesService = new DevicesClient(HOST);
export const devicesRequest = new DevicesRequest();
export const deviceSetDepartmentRequest = new DevicesSetDepartmentRequest();
export const deviceSetSiteRequest = new DevicesSetSiteRequest();
export const deviceSetCoachingProgramRequest
  = new DeviceSetCoachingProgramRequest();
export const deviceSetStatusRequest = new DeviceSetStatusRequest();
export const deviceHistoryRequest = new DeviceLinkHistoryRequest();
export const deviceReportRequest = new DevicesReportRequest();
export const userSiteRequest = new UserSiteRequest();
export const createDashboardFeedbackRequest = new CreateDashboardFeedbackRequest();

export const userService = new UserClient(HOST);
export const userRequest = new UserRequest();
export const userJobTitleRequest = new UserJobTitleRequest();
export const userDepartmentRequest = new UserDepartmentRequest();
export const userSetProgramRequest = new UserSetProgramRequest();
export const userActivationRequest = new UserActivationRequest();
export const userUpdateUserPrivateDataRequest
  = new UserUpdateUserPrivateDataRequest();
export const createUserRequest = new UserServiceCreateUserRequest();

export const userTaskService = new UserTaskClient(HOST);
export const taskVideosStreamRequest = new EventsForVideoRequest();
export const rebaWeightsStreamRequest = new EventsForRebaWeightRequest();
export const createUserTaskJobTitleRequest
  = new CreateUserTaskJobTitleRequest();
export const createUserTaskRequest = new CreateUserTaskRequest();
export const getUserTasksJobTitleRequest = new GetUserTasksJobTitleRequest();
export const getUserTaskByWorksessionIdRequest
  = new GetUserTasksByWorkSessionIdRequest();
export const updateUserTaskRequest = new UpdateUserTaskRequest();
export const removeUserTaskRequest = new RemoveUserTaskRequest();

export const overviewService = new OverviewClient(HOST);
export const overviewRequest = new NewOverviewRequest();
export const period = new Period();
export const overviewEmptyData = new NewData().toObject();

export const helpService = new HelpServiceClient(HOST);
export const helpRequest = new HelpRequest();
export const changeHelpStatusRequest = new ChangeStatusRequest();

export const dashboardUsersRequest = new DashboardUsersRequest();
export const createDashboardUserRequest = new CreateDashboardUserRequest();
export const editDashboardUserRequest = new UpdateDashboardUserRequest();
export const deleteDashboardUserRequest = new DashboardUserRequest();
export const getInviteLinkRequest = new DashboardUserRequest();
export const sendInviteRequest = new DashboardUserRequest();
export const resetDashboardUserPasswordRequest = new DashboardUserRequest();

export const taskDashboardVideoService = new TaskDashboardVideoServiceClient(
  HOST,
);
export const getTaskVideosRequest = new TaskVideoListRequest();
export const createTaskVideoRequest = new CreateTaskVideoRequest();
export const updateTaskVideoRequest = new UpdateTaskVideoRequest();
export const uploadTaskVideoRequest = new VideoUploadChunkRequest();
export const UploadTaskVideoRequest = VideoUploadChunkRequest;
export const taskVideoRequest = new TaskVideoRequest();

export const SSOService = new SsoServiceClient(HOST);
export const SSOURLRequest = new SSOUrlRequest();

export const taskAssessmentService = new TaskAssessmentServiceClient(HOST);
export const createAssessmentRequest = new CreateAssessmentRequest();
export const getAssessmentsRequest = new GetAssessmentsForVideoRequest();
export const updateAssessmentRequest = new UpdateAssessmentRequest();
export const deleteAssessmentRequest = new DeleteAssessmentRequest();
export const getAssessmentListRequest = new GetAssessmentListRequest();
export const getAssessmentsForVideoRequest
  = new GetAssessmentsForVideoRequest();

export const comparisonListRequest = new TaskVideoComparisonListRequest();
export const createComparisonRequest = new CreateTaskVideoComparisonRequest();
export const updateComparisonRequest = new UpdateTaskVideoComparisonRequest();
export const deleteComparisonRequest = new DeleteTaskVideoComparisonRequest();

export const createRebaWeightsRequest
  = new CreateTaskVideosRebaWeightsRequest();
export const getRebaWeightsRequest = new GetTaskVideoRebaWeightsRequest();
export const deleteRebaWeightsRequest = new DeleteTaskVideoRebaWeightsRequest();

// export const getRebaData = new GetRebaData()
export const taskOverviewService = new TaskOverviewServiceClient(HOST);
export const taskOverviewRequest = new SoterTaskOverviewRequest();

export const taskContactService = new ContactSalesClient(HOST);
export const taskContactRequest = new ContactSalesRequest();
export const contactSalesProductType = ContactSalesProductType;

export const resellersService = new ResellersClient(HOST);
export const resellersRequest = new ResellersRequest();

export const jobDictionaryService = new JobDictionaryClient(HOST);
export const createJobProfileRequest = new CreateJobDictionaryProfileRequest();
export const createJobDictionaryTagRequest
  = new CreateJobDictionaryTagRequest();
export const createTaskProfileRequest = new CreateJobDictionaryTaskRequest();
export const createVideoRequest = new CreateJobDictionaryYoutubeVideoRequest();
export const jobProfileRequest = new JobDictionaryProfileRequest();
export const jobDictionaryRequest = new JobDictionaryRequest();
export const editJobProfileRequest = new EditJobDictionaryProfileRequest();
export const editTaskProfileRequest = new EditJobDictionaryTaskRequest();
export const editLimitedTaskProfileRequest
  = new EditLimitedJobDictionaryTaskRequest();
export const createJobDictionaryProfile = new CreateJobDictionaryProfile();
export const removeVideoRequest = new RemoveJobDictionaryVideoRequest();
export const removeTaskProfileRequest = new RemoveJobDictionaryTaskRequest();
export const jobProfile = new JobDictionaryBodyProfile();
export const taskProfile = new JobDictionaryTask();
export const createTaskProfile = new CreateJobDictionaryTask();
export const editLimitedTaskProfile = new EditLimitedJobDictionaryTask();
export const metaJobProfile = new JobDictionaryProfile();
export const _cardsViewConfig = new CardsViewConfig();
export const getTaskRequest = new JobDictionaryTaskRequest();
export const _activityDemandBreakdown = new ActivityDemandBreakdown();
export const _criticalPhysicalDemands = new CriticalPhysicalDemands();
export const setTaskVideoToTaskProfileRequest = new SetTaskVideoRequest();
export const detachVideoRequest = new DetachJobDictionaryTaskVideoRequest();

export const emptyJobProfile = new JobDictionaryBodyProfile().toObject();
emptyJobProfile.cardsViewConfig = new CardsViewConfig().toObject();
emptyJobProfile.activityDemandBreakdown
  = new ActivityDemandBreakdown().toObject();
emptyJobProfile.criticalPhysicalDemands
  = new CriticalPhysicalDemands().toObject();

export const emptyTaskProfile = new CreateJobDictionaryTask().toObject();

enableDevTools([
  authService,
  userService,
  userTaskService,
  devicesService,
  overviewService,
  companiesService,
  adminService,
  adminServiceV2,
  adminServiceDevices,
  myCompanyService,
  helpService,
  jobDictionaryService,
  taskDashboardVideoService,
  taskAssessmentService,
  resellersService,
  taskAuthService,
  taskContactService,
  taskOverviewService,
  usersService,
  hubService,
  categoriesService,
  SSOService,
  dashboardUserChatProfileService,
]);
